@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@300;600&display=swap');

* {
    margin: 0;
    padding: 0;
}

body {
    background: #ffff;
    color: #222;
    font-family: 'Montserrat', sans-serif; /* Исправлена опечатка в названии шрифта */
    font-weight: 300;
}

.wrapper {
    width: 1280px;
    margin: 50px auto; /* Центрирование .wrapper по горизонтали */
}

header .logo {
    font-weight: 600;
    font-size: 20px;
}
header ul.nav {
    float: right;
    list-style: none;

}

header ul.nav li {
    display: inline;
    margin-left: 25px;
    cursor: pointer;
    transition:  opacity 500ms ease;
}
header ul.nav li:hover {
    opacity: 0.5;
}

main {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}
main .item {
    width: 30%;
    margin-bottom: 50px;
    background: #f5f5f5;
    overflow: hidden;
    position: relative;
    padding-bottom: 20px;
}
main .item img {
    width: 100%;
    border-radius: 10px 10px;
    transition: transform 500ms ease;
}

main .item img:hover {
    transform: scale(1.05);
}
main h2, main p, main b {
    margin: 10px 20px;
    color: #333;
}
main b {color: #5fa36a}

main .add-to-cart {
    position: absolute;
    right: 20px;
    bottom: 20px;
    background: #ca5252;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
    font-weight: 600; 
    transition:  transform 500ms ease; 
}

main .add-to-cart:hover {
    transform: scale(1.5) translateY(-5px);

}

footer {
    text-align: center;
    margin-top: 100px;
}